import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import db from "../Firebase";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ProductDetail.css';
import { useNavigate } from 'react-router-dom';
//import Header from './Header';
import Navbar from './Navbar';
import Moment from "moment";
import { extendMoment } from "moment-range";
import Breadcrumbs from './Breadcrumbs';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Footer from './Footer';
import Facebook from './FacebookPixel';
import Cookies from "js-cookie";
import { addCartData } from '../query';


const ProductDetails = () => {
    const moment = extendMoment(Moment);
    const navigate = useNavigate();
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [subscriptionType, setSubscriptionType] = useState("Everyday");
    const [quantity, setQuantity] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [customDays, setCustomDays] = useState({
        sunday: 0,
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0
    });
    const [intervalDays, setIntervalDays] = useState(2);
    const [nextDeliveryDate, setNextDeliveryDate] = useState('');
    const [showDatePickers, setShowDatePickers] = useState(false);
    const [showOnInterval, setShowOnInterval] = useState(false);
    const [showCustom, setShowCustom] = useState(false);
    const [activeFrequency, setActiveFrequency] = useState('Everyday');

    const incrementQuantity = () => setQuantity(quantity + 1);
    const decrementQuantity = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

    const incrementInterval = () => setIntervalDays(intervalDays + 1);
    const decrementInterval = () => setIntervalDays(intervalDays > 2 ? intervalDays - 1 : 2);

    const handleFrequencyClick = (frequency) => {
        if (frequency === "Everyday") {
            setIntervalDays(1);
            setQuantity(1);
        } else if (frequency === "On-Interval") {
            setIntervalDays(2);
            setQuantity(1);
        } else if (frequency === "One Time") {
            setIntervalDays(1);
            setQuantity(1);
        } else {
            setIntervalDays(0);
            setQuantity(0);
        }
        setActiveFrequency(frequency);
        setSubscriptionType(frequency);
    
        setShowDatePickers(frequency !== "One-Time");
        setShowOnInterval(frequency === "On-Interval");
        setShowCustom(frequency === "Custom");
    };
    
    const getTomorrow = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return formatDate(tomorrow);
    };
    
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };
    
    useEffect(() => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        const nextYear = new Date(today);
        nextYear.setFullYear(today.getFullYear() + 1);
    
        setStartDate(formatDate(tomorrow));
        setNextDeliveryDate(formatDate(tomorrow));
    }, []);
    
    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const doc = await db.collection('products_data').doc(id).get();
                if (doc.exists) {
                    const productData = doc.data();
                    setProduct(productData);
                    setSelectedPackage(productData.packagingOptions[0]);
                } else {
                    
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        };

        fetchProductDetails();
    }, [id]);

    const addToCart = async() => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const productToAdd = {
            product_name: product.productName,
            product: product.productName,
            product_id: product.product_id || "",
            productName: product.productName,
            image: product.image,
            price: parseInt(selectedPackage.price),
            package_unit: selectedPackage.packaging + ' ' + selectedPackage.pkgUnit,
            subscriptionType: subscriptionType,
            quantity: parseInt(quantity),
            startDate: new Date(startDate),
            endDate: endDate === '' ? new Date('3000-01-01') : new Date(endDate),
            sunday: subscriptionType == "Custom" ? parseInt(customDays.sunday) : 0,
            monday: subscriptionType == "Custom" ? parseInt(customDays.monday) : 0,
            tuesday: subscriptionType == "Custom" ? parseInt(customDays.tuesday) : 0,
            wednesday: subscriptionType == "Custom" ? parseInt(customDays.wednesday) : 0,
            thursday: subscriptionType == "Custom" ? parseInt(customDays.thursday) : 0,
            friday: subscriptionType == "Custom" ? parseInt(customDays.friday) : 0,
            saturday: subscriptionType == "Custom" ? parseInt(customDays.saturday) : 0,
            interval: subscriptionType === 'On-Interval' ? parseInt(intervalDays) : subscriptionType === "Everyday" ? 1 : 0,
            next_delivery_date: moment(startDate).format('YYYY-MM-DD'),
            reason: "",
            Timestamp: new Date(),
        };
        cart.push(productToAdd);
        localStorage.setItem('cart', JSON.stringify(cart));
        const utm_source = localStorage.getItem('utm_source');
        if(utm_source) {
        <Facebook title={`Click on Add to cart ${product.productName}`} />
        }
        updateCart();
        navigate('/cart');
    };

    const updateCart = ()=>{
      let phone = Cookies.get("user")
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
      phone = phone?.substring(phone.length-12, phone.length-2)|| "";
      addCartData(phone, cart);
    
    }
    const handleQuantityChange = (increment) => {
        setQuantity((prevQuantity) => {
            const newQuantity = increment ? prevQuantity + 1 : prevQuantity - 1;
            return newQuantity > 0 ? newQuantity : 1;
        });
    };

    const handleCustomDayChange = (day, value) => {
        setCustomDays(prevState => ({
            ...prevState,
            [day]: value
        }));
    };

    if (!product) {
        return <div>Loading...</div>;
    }

    const handleEndDate = (e) => {
        if (e.target.value === '') {
            setEndDate('3000-01-01');
        } else {
            setEndDate(e.target.value);
            
        }
        //(e) => 
    }
    return (
        <>

            <Navbar />
            <main>
<div class="header-details" style={{height:'100px', background:'black'}}>

</div>
                <div className="product-detail-container">
                    <div className="product-detail-card">
                        <div className="product-detail-left">
                            <img src={product.image} className="img-fluid rounded-start product-detail-page-image" alt={product.productName}
                            //  style={{ height: "30rem" }}
                              />
                        </div>
                        <div className="product-detail-right">
                            <h2>{product.productName}</h2>
                            <Link to="/" className='edit-button'>
                                <span style={{ color: 'black', fontWeight: 'bold', color: '#d2ab67', textDecoration: 'none' }}>Continue Shopping</span>
                            </Link>


                            <div className="frequency-buttons">
                                {product.packagingOptions.map(pkg => (
                                    <button
                                        key={pkg.packaging}
                                        className="frequency-button"
                                        onClick={() => setSelectedPackage(pkg)}
                                    >
                                        {pkg.packaging} {pkg.pkgUnit}
                                    </button>
                                ))}
                            </div>
                            <p className="product-price">₹{selectedPackage.price}</p>
                            <div className="product-description">
                                {/* <p>{product.productDescription}</p> */}
                                <p dangerouslySetInnerHTML={{ __html: product.productDescription }} />
                            </div>
                            <p className="order-info">
                                Order before 11:00 PM Today & get the delivery by <strong>{moment(nextDeliveryDate).format('MMMM D, YYYY')}</strong>
                            </p>
                            <p>Frequently</p>
                            <div className="frequency-buttons">
                                {['One Time', 'Everyday', 'Custom', 'On-Interval'].map(frequency => (
                                    <button
                                        key={frequency}
                                        className={`frequency-button ${activeFrequency === frequency ? 'active' : ''}`}
                                        onClick={() => handleFrequencyClick(frequency)}
                                    >
                                        {frequency}
                                    </button>
                                ))}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2rem' }}>
                                {!showCustom && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ fontSize: '18px', fontWeight: '600' }} className="theme-color">
                                            Quantity
                                        </span>
                                        <div className="quantity-selector" style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
                                            <button onClick={decrementQuantity}>-</button>
                                            <span style={{ margin: '0 10px' }}>{quantity}</span>
                                            <button onClick={incrementQuantity}>+</button>
                                        </div>
                                    </div>
                                )}

                                {showOnInterval && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5rem' }}>
                                        <span style={{ fontSize: '18px', fontWeight: '600' }} className="theme-color">
                                            |
                                        </span>
                                        <span style={{ marginLeft: '2rem', fontSize: '18px', fontWeight: '600' }} className="theme-color">
                                            Every
                                        </span>
                                                                                <div className="quantity-selector" style={{ display: 'flex', alignItems: 'center', marginLeft: '2rem' }}>
                                            <button onClick={decrementInterval}>-</button>
                                            <span style={{ margin: '0 10px' }}>{intervalDays}</span>
                                            <button onClick={incrementInterval}>+</button>
                                        </div>
                                        <p className="theme-color" style={{ fontSize: '16px', marginLeft: '10px' }}>Day(s)</p>
                                    </div>
                                )}
                            </div>
                            {showCustom && (
                                <div className="custom-days" style={{ display: "ruby-text" }}>
                                    {Object.keys(customDays).map((day) => (
                                        <div key={day} className="form-group">
                                            <label htmlFor={day}>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id={day}
                                                value={customDays[day]}
                                                onChange={(e) => handleCustomDayChange(day, e.target.value === '' ? '1' : e.target.value)}
                                                min="0"
                                                onBlur={(e) => {
                                                    if (e.target.value === '') {
                                                        handleCustomDayChange(day, '1');
                                                    }
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            {showDatePickers ? (
                                <div className="row">
                                    <div className="col-6 mt-4">
                                        <h5 style={{ fontSize: "12px" }}>Starts On</h5>
                                        <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value) }min={getTomorrow()} />
                                    </div>
  
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-6 mt-4">
                                        <h5 style={{ fontSize: "12px" }}>Delivery On</h5>
                                        <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} min={getTomorrow()}/>
                                    </div>
                                </div>
                            )}
                            <button className="subscribe-button" style={{ marginTop: '10px', fontSize: 'small', backgroundColor: 'black' }} onClick={addToCart}>Add To Cart</button>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default ProductDetails;