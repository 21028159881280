    import React, { useState } from "react";
    import db from "../Firebase";
    import { collection, addDoc, serverTimestamp } from "firebase/firestore";
    import emailjs from "emailjs-com";

    const InquiryForm = ({ onClose }) => {
    const [formData, setFormData] = useState({ name: "", email: "",  mobile:"", message: "" });
    const [message, setMessage] = useState({ text: "", type: "" });

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        let updatedValue = value;
    
        if (name === "name") {
            // Allow only letters and spaces
            updatedValue = value.replace(/[^A-Za-z\s]/g, '');
        } else if (name === "mobile") {
            // Allow only numbers and limit to 10 digits
            updatedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
        }
    
        setFormData({ ...formData, [name]: updatedValue });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
        // Save inquiry to Firestore
        await addDoc(collection(db, "inquiries"), { ...formData, timestamp: serverTimestamp() });

        // Send email using EmailJS
        await emailjs.send(
            "service_9gkr6hu",  
            "template_d6e822d", 
            {
            name: formData.name,
            email: formData.email,
            mobile: formData.mobile,
            message: formData.message,
            },
            "vS1fGTreJ2Hxj2m1V" 
        );

        setMessage({ text: "Thank You for Contacting, Our Team will connect with you shortly.", type: "success" });

        // Reset form fields
        setFormData({ name: "", email: "", message: "" });

        // Close popup after 1.5 seconds
        setTimeout(() => {
            setMessage({ text: "", type: "" });
            if (onClose) onClose(); // Call onClose function if provided
        }, 1500);
        
        } catch (error) {
        console.error("Error submitting inquiry: ", error);
        setMessage({ text: "Failed to submit inquiry. Please try again.", type: "error" });

        setTimeout(() => setMessage({ text: "", type: "" }), 3000);
        }
    };

    return (
        <div className="popup">
        <div className="popup-content">
            <button className="close-btn" onClick={onClose}>×</button>
            <h3 className="text-dark mb-3 text-center" style={{ fontFamily: 'HurmeGeometricSans1, sans-serif' }}>
  Request a Callback
</h3>

            {message.text && (
            <div className={`alert ${message.type === "success" ? "alert-success" : "alert-danger"}`}>
                {message.text}
            </div>
            )}
            <form onSubmit={handleSubmit}>
            <input type="text" name="name" className="form-control mb-3" placeholder="Your Name" value={formData.name} onChange={handleChange} required  style={{ fontFamily: 'HurmeGeometricSans1, sans-serif' }}/>
            <input type="email" name="email" className="form-control mb-3" placeholder="Your Email" value={formData.email} onChange={handleChange} required  style={{ fontFamily: 'HurmeGeometricSans1, sans-serif' }}/>

            <input
  type="text" // Using text for full control
  name="mobile"
  className="form-control mb-3"
  placeholder="Your Phone"
  value={formData.mobile}
  onChange={handleChange}
  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 11)} // Prevents non-numeric input
  pattern="[0-9]{10}" // Ensures exactly 12 digits before submission
  maxLength="10" // Limits input to 12 digits
  inputMode="numeric" // Opens numeric keypad on mobile
  required
  title="Please enter a valid 10-digit phone number"
  style={{ fontFamily: 'HurmeGeometricSans1, sans-serif' }}
/>




            <textarea name="message" placeholder="Your Message" className="form-control" value={formData.message} onChange={handleChange} required style={{ fontFamily: 'HurmeGeometricSans1, sans-serif' }} />

            <button type="submit" className="btn btn-dark mt-3" style={{ borderRadius:"20px" ,fontFamily: 'HurmeGeometricSans1, sans-serif',background:" #d2ab67",border:"none"}}>Submit</button>
            </form>
        </div>
        </div>
    );
    };

    export default InquiryForm;
