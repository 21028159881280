import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import Navbar from './Navbar'; 
import Footer from './Footer';

function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: '',
        captchaInput: ''
    });
    const [generatedCaptcha, setGeneratedCaptcha] = useState('');
    const [captchaMessage, setCaptchaMessage] = useState('');

    const generateCaptcha = () => {
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let captchaLength = 6;
        let captcha = '';
        for (let i = 0; i < captchaLength; i++) {
            let randomIndex = Math.floor(Math.random() * characters.length);
            captcha += characters[randomIndex];
        }
        setGeneratedCaptcha(captcha);
    };

    useEffect(() => {
        generateCaptcha();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (isNaN(value) || value.length > 11) {
            // If the input is not a number, do not update the state
            return;
        }

        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formData.captchaInput !== generatedCaptcha) {
            setCaptchaMessage('Invalid CAPTCHA, please try again.');
            generateCaptcha();
            return;
        }

        setCaptchaMessage('');

        emailjs.send('service_9gkr6hu', 'template_d6e822d', {
            name: formData.name,
            email: formData.email,
            mobile: formData.mobile,
            message: formData.message
        }, 'vS1fGTreJ2Hxj2m1V')
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert('Email sent successfully!');
            // Optionally, clear the form here
            setFormData({
                name: '',
                email: '',
                mobile: '',
                message: '',
                captchaInput: ''
            });
            generateCaptcha(); // Regenerate CAPTCHA
        }, (err) => {
            console.error('FAILED...', err);
            alert('Failed to send email.');
        });
    };

    return (
        <>
            <div className="header-banner-contact">
                <div className="container banner-heading banner-contact">
                    <Navbar />
                    <div className="white-cow-img">
                        <center><img src="images/white-cow.png" alt="White Cow" /></center>
                    </div>
                    <h2 className="text-center text-light texts">Contact Us</h2>
                </div>
            </div>
            <div className="container main-container-contactUs">
                <div className="row content-center">
                    <div className="col-lg-10 col-md-8 col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3>Corporate Office</h3>
                                <h4 className="text-col">Whyte Farms</h4>
                                <address>
                                    NH-8, Near Rajokri Flyover, Rajokri, Delhi - 110038, India
                                </address>
                                <div className="contact-info">
                                    <span><i className="fas fa-mobile-alt"></i> 9873710709</span><br />
                                    <span><i className="far fa-envelope"></i> care@whytefarms.com</span>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3>Farm Address</h3>
                                <h4 className="text-col">Whyte Farms LLP</h4>
                                <address>
                                    Village Shahbad, Tehsil Tijara, Distt. Alwar, Rajasthan -301411, India
                                </address>
                                <span>Timing - 9.00 AM to 7.00 PM</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 form-content">
                    <div className="col-lg-10 col-md-10 col-sm-12">
                        <form onSubmit={handleSubmit} id="contactForm">
                            <div className="mb-3">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Full Name *"
                                    className="form-control br"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email ID *"
                                    className="form-control br"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    name="mobile"
                                    placeholder="Mobile Number *"
                                    className="form-control br"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    required
                                    pattern='[0-9]'
                                />
                            </div>
                            <div className="mb-3">
                                <textarea
                                    name="message"
                                    className="form-control br"
                                    rows="3"
                                    placeholder="Write Message/Any Query ? *"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                            </div>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    name="captchaInput"
                                    placeholder="Enter Captcha Code *"
                                    className="form-control br"
                                    value={formData.captchaInput}
                                    onChange={handleChange}
                                    required
                                />
                                <small id="captchaHelp" className="form-text text-muted">
                                    Please enter the CAPTCHA code above.
                                </small>
                            </div>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    id="generatedCaptcha"
                                    className="form-control br"
                                    value={generatedCaptcha}
                                    readOnly
                                />
                            </div>
                            <div className="mb-3">
                                <button
                                    type="button"
                                    onClick={generateCaptcha}
                                    className="captcha-btn br"
                                >
                                    Generate CAPTCHA
                                </button>
                            </div>
                            <input type="submit" className="form-control br bg-colss" value="Send" />
                        </form>
                        <div id="captchaMessage" className="mt-3">{captchaMessage}</div>
                    </div>
                </div>
            </div>
            <br />
            <Footer />
        </>
    );
}

export default ContactUs;
