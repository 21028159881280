import { createContext, useState, useContext } from "react";

const InquiryContext = createContext();

export const InquiryProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openForm = () => setIsOpen(true);
    const closeForm = () => setIsOpen(false);

    return (
        <InquiryContext.Provider value={{ isOpen, openForm, closeForm }}>
            {children}
        </InquiryContext.Provider>
    );
};

export const useInquiry = () => useContext(InquiryContext);
