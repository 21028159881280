import React, { useState } from "react";
import InquiryForm from "./InquiryForm";

const FloatingButton = () => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div>
      <button className="floating-btn" onClick={() => setShowPopup(true)}>
      Enquire Now
      </button>

      {showPopup && <InquiryForm onClose={() => setShowPopup(false)} />} {/* Pass onClose */}
    </div>
  );
};

export default FloatingButton;
