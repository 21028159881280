import React, { useState, useEffect, useContext } from "react";
import db from "../Firebase";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
// import Header from "./Header";
import "./dashboard.css";
import Navbar from "./Navbar";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Facebook from "./FacebookPixel";
// import 'animate.css';
import Footer from "./Footer";
import ReactPixel from 'react-facebook-pixel';
const Dashboard = () => {
  const [data, setData] = useState([]);
  const [cart, setCart] = useState([]);

  const [customerData, setCustomerData] = useState({});
  const [totalOrderValue, setTotalOrderValue] = useState(0);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <button className="slick-prev" aria-label="Previous" type="button">←</button>,
    nextArrow: <button className="slick-next" aria-label="Next" type="button">→</button>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  };

  useEffect(() => {
    const unsubscribe = db
      .collection("customers_data")
      .doc("customer_id")
      .onSnapshot((snapshot) => {
        setCustomerData(snapshot.data());
      });

    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('282551210445191') // facebookPixelId
        ReactPixel.pageView()
      })


    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const orderItems = [];
    const orderTotal = orderItems.reduce(
      (total, item) => total + item.price,
      0
    );
    setTotalOrderValue(orderTotal);
  }, []);

  useEffect(() => {
    const unsubscribe = db
      .collection("products_data")
      .orderBy("created_date", "desc")
      .onSnapshot((snapshot) => {
        setData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((product) => product.inStock)
        );
      });

    console.log("Loading cart data from localStorage...");
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      console.log("Cart data found:", JSON.parse(savedCart));
      setCart(JSON.parse(savedCart));
    } else {
      console.log("No cart data found in localStorage.");
    }

    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //     console.log("Saving cart data to localStorage:", cart);
  //     localStorage.setItem('cart', JSON.stringify(cart));
  // }, [cart]);

  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  useEffect(() => {
    console.log("Fetching cart data from localStorage...");
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      console.log("Cart data found:", JSON.parse(savedCart));
      setCart(JSON.parse(savedCart));
    } else {
      console.log("No cart data found in localStorage.");
    }
  }, [data]);

  const handleAddToCart = (product) => {
    setCart([...cart, product]);
  };

  const handleRemoveFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
  };

  const handleCheckout = () => {
    if (user) {
      // User is logged in, redirect to details page
      navigate("/");
    } else {
      // User is not logged in, redirect to login page
      navigate("/login");
    }
  };

  const gotocart = () => {
    navigate("/cart");
  };

  const gotologin = () => {
    navigate("/login");
  };

  const addProduct = (id) => {
    const utm_source = localStorage.getItem("utm_source");
    if (utm_source) {
      <Facebook title={"Click on Add of homepage"} />;
    }
    navigate(`/product_details/${id}`);
  };

  const buyNow = () => {
    navigate(`/product_details/HNgdBpryadraSsfYv1zh`);
  };

  const handlePrev = () => {
    const carousel = document.querySelector(".carousel-container");
    carousel.scrollBy({ left: -carousel.clientWidth, behavior: "smooth" });
  };

  const handleNext = () => {
    const carousel = document.querySelector(".carousel-container");
    carousel.scrollBy({ left: carousel.clientWidth, behavior: "smooth" });
  };

  const bookFarmVisit = () => {
    navigate('/bookyourfarm')
    window.scrollTo(0, 0);
  }

  return (
    <>
      <header>
        <Navbar />
      </header>

      <div className="video-container"
      >
        {/* <Header/> */}
        <video autoPlay loop muted>
          <source src="images/1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-container-text">

          <h1>
            Freshness Delivered,
            <br />
            From Our Farm
          </h1>
          <p class="mt-2  animate__fadeInDown">Welcome to a world where “farm fresh” is
            not just a phrase, it's a<br /> promise we deliver
            to your home, enriching your<br /> life with pure,
            organic goodness!</p>
        </div>
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col-md-6 col-sm-12 col-lg-6">
            <div class="second-section-image">
              <img src="images/5.png" style={{ width: "400px" }} />{" "}
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-lg-6">
            <div class="second-section-content">
              <p class="text-center">
                <span style={{ fontSize: '25px', fontWeight: '500' }}>Milk That's</span>
              </p>
              <h3 class="text-center theme-color title mb-2" style={{ fontSize: '30px', fontWeight: '700' }}>Farm Fresh</h3>
              <div class="cow-img-icon">
                <div class="icon-img"><img src="images/cow.png" /></div>
              </div>
              <p style={{ fontSize: '18px' }}>
                Our milk is produced at our farm and
                delivered fresh every morning. It doesn’t
                contain any preservatives or additives. You can
                taste the freshness in every gulp as it is
                delivered within as little as 8 hours of milking.
              </p>
              <p class="text-center theme-color mt-3 mb-3" style={{ fontSize: '18px' }}>
                <b> &#x20B9;88 Per Litre</b>
              </p>
              <div class="milk-qualtity-content">
                <div class="items">
                  <p>
                    <b>100%</b>
                  </p>
                  <span>Pure</span>
                </div>
                <div class="items">
                  <p>
                    <b>Free</b>
                  </p>
                  <span>Delivery</span>
                </div>
                <div class="items">
                  <p>
                    <b>High</b>
                  </p>
                  <span>Quality</span>
                </div>
              </div>
              <div class="second-section-button mt-4 mb-5">
                <div
                  class="item-button"
                >
                  <button type="button" class="btn btn-dark buy-now-btn" onClick={buyNow}>
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container">
        <div class="third-section-content">
          <p class="text-center theme-color">
            <i>Explore Our</i>
          </p>
          <h3 class="text-center title">Other Products</h3>
          <div class="cow-img-icon">
            <div class="icon-img"><img src="images/cow.png" /></div>
          </div>
        </div>
        <div class="third-section-image">
          <div className="product-carousel">
            <Slider {...settings}>
              {data.map((item) => (
                <div key={item.id} className="product-item">
                  <div class="homepage-product-image">
                    {/* <img src={`images/${item.productName.replace(/\\/g, '/').replace(/ /g, '%20')}.png`}
                      alt={item.productName} height={'180px'} /> */}
                      
                      <img src={item.image}className="card-img-top product-item-image"alt={item.productName}  height={'180px'} />
                      
                      </div>
                  <p style={{ fontSize: '16px', fontWeight: '600' }}>{item.productName}</p>
                  <div class="product-btn">
                    <div class="btn-1">
                      <button type="button" class="btn btn-dark product-add-btn" onClick={() => addProduct(item.id)}>Add</button>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/* <div className="owl-carousel owl-theme">
          {data.map((item) => (
            <div class="item">
                <img src={`images/${item.productName.replace(/\\/g, '/').replace(/ /g, '%20')}.png`}
                alt={item.productName} />
                <p style={{fontSize:'12px' , fontWeight:'600'}}>{item.productName}</p>
              <div class="product-btn">
                <div class="btn-1">
                  <button type="button" class="btn btn-dark" onClick={() => addProduct(item.id)}>Add</button>
                </div>
              </div>
            </div>
            ))}
          </div> */}
        </div>
      </div>
      <div class="container-fluid facility-new-section mt-5">
        <div class="facility-proovide-new">
          <div class="facility-item-new">
            <div class="fac-services">
              <img src="images/icons/1.png" class="fac-services-img" />
              <p class="text-center theme-color fac-service-p">Free Home</p>
              <p class="text-center theme-color fac-services-text">Delivery</p>
            </div>
            <div class="fac-services">
              <img src="images/icons/2.png" class="fac-services-img no-min-img" />
              <p class="text-center theme-color fac-service-p">No Minimum</p>
              <p class="text-center theme-color fac-services-text">Order</p>
            </div>
            <div class="fac-services">
              <img src="images/icons/4.png" class="fac-services-img custom-img cus-ser" />
              <p class="text-center theme-color fac-service-p">Custom</p>
              <p class="text-center theme-color fac-services-text">Subscription</p>
            </div>
            <div class="fac-services">
              <img src="images/icons/3.png" class="fac-services-img cus-ser" />
              <p class="text-center theme-color fac-service-p">Pay Online</p>
              <p class="text-center theme-color fac-services-text">Or Cash</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="facility-section mt-5">
        <div class="container facility-provide-items">
<div class="facility-item">
<img src="images/icons/1.png" />
            <div class="facility-content">
            <span class="theme-color">Free Home</span><br />
            <span class="theme-color">Delivery</span></div>
</div>
<div class="facility-item">
<img src="images/icons/2.png" />
            <div class="facility-content">
            <span class="theme-color text-center">No Minimum</span><br />
            <span class="theme-color text-center">Order</span></div>
</div>
<div class="facility-item">
<img src="images/icons/4.png" />
          <div class="facility-content">
            <span class="theme-color ">Customer</span><br />
            <span class="theme-color ">Subscription</span></div>
</div>
<div class="facility-item">
<img src="images/icons/3.png" />
<div class="facility-content">
            <span class="theme-color">Pay Online<br /></span>
            <span class="theme-color"> Or Cash</span></div>
</div>
        </div>
      </div> */}
      <div class="best-food-section mb-5 container-fluid">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-sm-12 container p-5 mt-5">
            <p class="text-center mb-3">
              <span style={{ fontSize: '25px', fontWeight: '500' }}>The best food for</span>
            </p>
            <h3 class="theme-color text-center title" style={{ fontSize: '30px', fontWeight: '700' }}>OUR COWS</h3>
            <div class="cow-img-icon">
              <div class="icon-img"><img src="images/cow.png" /></div>
            </div>
            <div class="best-food-cow">
              <p>
                We grow organic fodder at the farm itself so
                that our cows get to eat the best & most
                nutritious food. Our cows are free to roam,
                have access to fresh water and get quality
                medical care. They aren’t treated with growth
                hormones ever!
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="best-food-image mt-5">
              <img src="images/9.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="best-food-section mb-5 container-fluid">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-sm-12 mt-5">
            <div class="care-image">
              <img src="images/10.png" />
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12 container p-5 mt-5">
            <p class="text-center mb-3" style={{ marginTop: '3rem' }}>
              <span style={{ fontSize: '30px', fontWeight: '500' }}>Hands-On care</span>
            </p>
            <h3 class="theme-color text-center title" style={{ fontSize: '30px', fontWeight: '700' }}>HANDS OFF MILKING</h3>
            <div class="cow-img-icon">
              <div class="icon-img"><img src="images/cow.png" /></div>
            </div>
            <div class="care-cow">
              <p>
                Our industry leading technology means no
                human hand touches the milk we produce,
                keeping it cleaner, hygienic and pure.
              </p>
            </div>
          </div>
        </div>
      </div>


      <div class="seventh-section-content">
        <center>
          <img src="images/8.png" />
        </center>
      </div>

      <div class="eight-section-faq">
        <div class="container">
          <div class="faq-heading text-center mb-5">
            <h3 class="theme-color title">FREQUENTLY ASKED</h3>
            <h3 class="title">QUESTIONS</h3>
          </div>
          <div class="faq-accordion">
            <div class="accordion" id="accordionExample">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          Where is Whyte Farms located?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Whyte Farms is located near a small village known as
                          Shahbad (Tehsil Tijara), in Alwar District, Rajasthan.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Can we visit the farm?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Yes, of course. We would be more than delighted to
                          give you a tour of the farm. Not just that, we will
                          shower you with a lot of goodies for your interest. A
                          prior appointment is must though. In our town we don't
                          let our guests go without a celebration.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What facilities are available on the farm?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Whyte Farms is spread over 25 acres and built to
                          international standards. It's a world-class dairy farm
                          that uses state-of-the-art-facility and processes
                          which are essential to keep the cows comfortable and
                          happy, such as climate controllers, massage brushes,
                          cleanliness tools and high quality feed and water.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          How is Whyte Farms different from other farms?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Whyte Farms is one of the most technologically
                          advanced dairies in India. Whyte Farms in an
                          integrated dairy farm where its has its own
                          agricultural lands for fodder, state of the art barns,
                          fully automated milking parlour, chilling units,
                          pasteurization unit, packaging line, cold stores as
                          well as our own cold-chain delivery vans and bikes for
                          home delivery. In other words, we have all that it
                          takes to grow goodness. We are a self sufficient,
                          sustainable, high tech organic farm.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFive">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          What other activities are carried at the farm?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          We do Organic Vegetable Farming, processing of
                          Vermicompost (Kechua Manure), Eco Tourism, and Carbon
                          Sink (15000 trees planted in the last on year)
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSix">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="true"
                          aria-controls="collapseSix"
                        >
                          Why is our milk the best Milk in the market?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Whyte Farms is not an aggregator of milk but we
                          produce our own milk. As producers of milk, we have
                          control over every minute process involved in
                          producing good wholesome milk. We take special care of
                          each detail, beginning from the healthiest breed of
                          cows, the right mix of organic fodder, regular
                          supervision of cow's health and comfort, milking
                          hygiene, maintaining highest standards of sheds and
                          barns, and finally ensuring safe delivery of the cow's
                          wholesome milk straight from our farm to your home. We
                          don't put any preservatives and adulterants We don't
                          put any chemicals in the fodder for cows, they are
                          only fed farm grown organic fodder. We don't give
                          antibiotics or hormonal steroids to our cows. The
                          milking and packaging process is fully mechanised,
                          hence completely hygienic. We keep our cows happy.
                          They are free to roam, bask in the sun or rest in
                          their comfy sheds. Better still, just try a glass of
                          our Milk and you will know why its better than any
                          other milk and why it's so delicious!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="client-heading">
          <p class="theme-color text-center"></p>
        </div>
      </div>

      <div class="our-clients-section mb-5">
        <div class="container">
          <div class="third-section-content">
            <p class="text-center theme-color">
              <i>Our</i>
            </p>
            <h3 class="text-center title">Clients</h3>
            <div class="cow-img-icon">
              <div class="icon-img"><img src="images/cow.png" /></div>
            </div>
          </div>
          <div class="third-section-image">
            <div class="owl-carousel owl-theme">
              <div class="item">
                <img src="images/client/client1.webp" alt="Image 1" />
              </div>
              <div class="item">
                <img src="images/client/client2.webp" alt="Image 1" />
              </div>
              <div class="item">
                <img src="images/client/client3.webp" alt="Image 1" />
              </div>
              <div class="item">
                <img src="images/client/client4.webp" alt="Image 1" />
              </div>
              <div class="item">
                <img src="images/client/client5.webp" alt="Image 1" />
              </div>
              <div class="item">
                <img src="images/client/client6.webp" alt="Image 1" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="leading-farma">
        <div class="container">
          <div class="row mt-3">
            <div class="col-md-6 col-lg-6 col-sm-12">
              <div class="third-section-content">
                <h3 class="text-center">
                  We're leading the way by taking dairy farming to next level
                </h3>
                <div class="cow-img-icon">
                  <div class="icon-img cow-leading-icon"><img src="images/cow.png" /></div>
                </div>
                <p style={{ fontSize: '18px', textAlign: 'justify', marginBottom: '20px' }}>Our Farm is FSSAI and ISO Certified. Our Key focus is to produce premium quality products by following international standards. Take a tour. Have laugh! Its always fun at Whyte Farms.</p>
                <button type="button" class="btn btn-dark farm-visit mt-3" onClick={bookFarmVisit}>Book Farm Visit</button>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 mt-5">
              <div class="leading-banner">
                <img src="images/7.png" alt="Image 1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="savor-section">
        <div class="container">
          <div class="row mt-5 align-items-center">
            <div class="col-md-6 col-lg-6 col-sm-12">
              <div class="leading-banner">
                <img src="images/phonehand.png" alt="Image 1" />
              </div>

            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
              <div class="third-section-content">
                <h3 class="text-center">
                  Savor the Convenience
                </h3>
                <h3 class="theme-color text-center title">APP-ETIZING FRESHNESS</h3>
                <div class="cow-img-icon">
                  <div class="icon-img"><img src="images/cow.png" /></div>
                </div>
                <p style={{ fontSize: '18px' }}>Your Journey to farm fresh Organic goodness is just a tap away. Download the Whytefarms app now to:</p>
                <ul style={{ fontSize: '18px' }}>
                  <li style={{ fontSize: '18px' }}>Place and Manage Orders</li>
                  <li style={{ fontSize: '18px' }}>Pause/Resume Subscriptions</li>
                  <li style={{ fontSize: '18px' }}>Recharge Wallet</li>
                </ul>
                <a href="https://apps.apple.com/in/app/whyte-farms/id1330297797"  target='_blank'><button type="button" class="btn btn-dark download-app">Download The App</button></a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="our-clients-section">
        <div class="container mb-5 mt-5">
          <div class="third-section-content">
            <p class="text-center theme-color">
              <i>As</i>
            </p>
            <h3 class="text-center title">FEATURED IN</h3>
            <div class="cow-img-icon">
              <div class="icon-img"><img src="images/cow.png" /></div>
            </div>
          </div>
          <div class="third-section-image">
          

          
            <div class="feature-section">
              <div class="item">
                <img src="images/feature/1.png" alt="Image 1" />
              </div>
              <div class="item">
                <img src="images/feature/2.png" alt="Image 1" />
              </div>
              <div class="item">
                <img src="images/feature/3.png" alt="Image 1" />
              </div>
              <div class="item">
                <img src="images/feature/4.png" alt="Image 1" />
              </div>
              {/* <div class="item">
                <img src="images/feature/5.png" alt="Image 1" />
              </div> */}
            </div>
            
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Dashboard;
